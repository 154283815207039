import { CssBaseline } from "@material-ui/core";
import Navigator from "./navigation/Navigator";
function App() {
  return (
    <>
      <CssBaseline />
      <Navigator />
    </>
  );
}

export default App;
