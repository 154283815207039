export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.common.GRAY
  },
  roundButton: {
    width: 175,
    borderRadius: 20
  },
  header: {
    fontFamily: "Brandon-Bold"
  }
});
