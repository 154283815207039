export const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 64,
    paddingBottom: 64,
    //height: 400,
    width: "100%",
    backgroundColor: "#fff"
  },
  cardRoot: {
    backgroundColor: theme.palette.common.GRAY,
    minWidth: 397.28,
    height: 273,
    marginLeft: 16,
    marginRight: 16
  },
  cardSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "auto",
    paddingLeft: 32,
    paddingRight: 32
  }
});
