export const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    width: "100%",
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 32,
    paddingRight: 32
  },
  roundButton: {
    minWidth: 110,
    borderRadius: 20
  },
  timelineDot: {
    alignItems: "center",
    background: theme.palette.primary.main,
    borderRadius: 64,
    boxShadow: `inset 0 0 5px rgba(0,0,0,.15), 0 0 7px ${theme.palette.secondary.main}`,
    display: "flex",
    height: 64,
    justifyContent: "center",
    width: 64
  },
  timelineOppositeContent: {
    flex: 0
  },
  mobileTimelineSection: {
    marginTop: 32,
    marginBottom: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  separator: {
    width: "15%",
    height: 2,
    backgroundColor: "#EBEBEB",
    marginLeft: 12,
    marginRight: 12
  }
});
