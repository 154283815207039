const PREFIX = "spark2start:";

export const setLocal = (key, val) => {
  let data = val;
  if (typeof val !== "string") {
    switch (typeof val) {
      case "object":
        data = JSON.stringify(val);

        break;
      default:
        data = val + "";
    }
  }
  localStorage.setItem(PREFIX + key, data);
};

export const getLocal = (key) => {
  return localStorage.getItem(PREFIX + key);
};

export const setSession = (key, val) => {
  let data = val;
  if (typeof val !== "string") {
    switch (typeof val) {
      case "object":
        data = JSON.stringify(val);

        break;
      default:
        data = val + "";
    }
  }

  sessionStorage.setItem(PREFIX + key, data);
};

export const getSession = (key) => {
  return sessionStorage.getItem(PREFIX + key);
};

export const clearSession = () => {
  sessionStorage.clear();
};
