export const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.secondary.main
  },
  newsletterSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 100,
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid white"
  },
  mobileNewsletterSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 200,
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid white"
  },
  emailInput: {
    paddingLeft: 16,
    borderRadius: 20,
    marginLeft: 16,
    marginRight: 16,
    color: "white",
    height: 40,
    width: 250,
    backgroundColor: "transparent",
    border: "1px solid white",
    "&:focus": {
      outline: "none",
      border: "2.5px solid white"
    }
  },
  roundButton: {
    minWidth: 110,
    borderRadius: 20
  }
});
