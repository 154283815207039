export const styles = (theme) => ({
  container: {
    width: "100%"
  },
  textPrimary: {
    color: "white",
    fontFamily: "Brandon-Bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem"
    }
  },
  textSecondary: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      color: "black"
    }
  },
  mobileInfoSection: {
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center"
  },
  roundButton: {
    minWidth: 110,
    borderRadius: 20
  }
});
