import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const PRIMARY = "#1F896F";
const SECONDARY = "#28283A";
const GRAY = "#F8F9FB";

const theme = createMuiTheme({
  palette: {
    primary: { main: PRIMARY },
    secondary: { main: SECONDARY },
    common: {
      GRAY
    }
  },
  typography: {
    fontFamily: ["Brandon", "Roboto", "open-sans"].join(","),
    useNextVariants: true
  }
});

export default responsiveFontSizes(theme);
