import React from "react";
import { Typography, withStyles, Card, CardContent } from "@material-ui/core";
import { styles } from "./styles";

const PARTNERS = [
  {
    name: "Udemy",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/4/48/Dell_Logo.svg"
  },
  {
    name: "Dell",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/4/48/Dell_Logo.svg"
  },
  {
    name: "Unicentric",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/4/48/Dell_Logo.svg"
  },
  {
    name: "CCAC",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/4/48/Dell_Logo.svg"
  }
];

const Partners = React.forwardRef(({ classes }, ref) => {
  return (
    <div className={classes.root} ref={ref}>
      <Typography style={{ alignSelf: "center", padding: 16 }}>
        OUR PARTNERS
      </Typography>
      <div className={classes.cardSection}>
        {PARTNERS.map((partner, i) => (
          <Card className={classes.cardRoot} key={`${i}`} variant="outlined">
            <CardContent>
              <img
                alt={partner.name + " company logo"}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={partner.logoUrl}
              />
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(Partners);
