import React from "react";
import s2sImage from "../../assets/s2s_image_3.png";
import { Grid, Box, Button, Typography, withStyles } from "@material-ui/core";
import { styles } from "./styles";

const Problem = React.forwardRef(({ classes, onApply }, ref) => {
  return (
    <div className={classes.root} ref={ref}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX={3}
            paddingY={3}
            height="100%"
          >
            <Typography variant="h4" className={classes.header}>
              THE PROBLEM
            </Typography>
            <Typography style={{ marginTop: 16, marginBottom: 16 }}>
              Over the past decade, we have seen a 23% increase in the number of
              people employed by the technology sector. With the third highest
              average wage of any major occupational group in 2019, the computer
              and technology industries continue to grow at outstanding rates.
              Despite this growth, the opportunities presented have not been
              evenly distributed. For example, the black community have
              accounted for only 7% of the tech workforce in 2010, 8% in 2015
              and 9% in 2020 while making up 13.4% of the total US
              population.  Our goal is to eliminate this and other similar
              disparities across the spectrum of minority communities. We aim to
              teach coding, technical, and professional skills through a joint
              curriculum of classroom and real-world experience. If you possess
              the spark, we will happily provide your start.
            </Typography>
            <Button
              color="primary"
              variant="contained"
              className={classes.roundButton}
              onClick={onApply}
            >
              Apply Now
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingX={3}
            paddingY={3}
          >
            <img
              style={{ maxWidth: "100%", maxHeight: 400 }}
              src={s2sImage}
              alt="meeting"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(Problem);
