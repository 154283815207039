import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Home, ApplicationScreen } from "../screens/index";
import { HOME, APPLICATION } from "./routes";

const Navigator = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={HOME}>
        <Home />
      </Route>
      <Route path={APPLICATION}>
        <ApplicationScreen />
      </Route>
      <Redirect from="/*" to={HOME} />
    </Switch>
  </BrowserRouter>
);

export default Navigator;
