import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Hidden,
  IconButton,
  isWidthUp,
  Drawer,
  List,
  ListItem,
  withWidth,
  Button,
  withStyles
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import logo from "../../assets/s2s_logo_small.png";
import { styles } from "./styles";
const NavBar = ({ classes, width, onItemSelected }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const openMobileDrawer = () => setMobileDrawerOpen(true);
  const closeMobileDrawer = () => setMobileDrawerOpen(false);
  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          {/* <Hidden smUp>
            <IconButton onClick={openMobileDrawer} color="primary">
              <Menu />
            </IconButton>
          </Hidden> */}
          <img src={logo} style={{ width: 171, height: 46 }} />
        </Box>
        <Hidden smDown>
          {/* <Button variant="text" onClick={() => onItemSelected("partners")}>
            Partners
          </Button> */}
          <Button variant="text" onClick={() => onItemSelected("approach")}>
            Approach
          </Button>
          <Button variant="text" onClick={() => onItemSelected("problem")}>
            Problem
          </Button>
          {/* <Button variant="text" onClick={() => onItemSelected("testimonials")}>
            Testimonials
          </Button> */}
          <Button variant="text" onClick={() => onItemSelected("stats")}>
            Coding Employment
          </Button>
          <Button variant="text" onClick={() => onItemSelected("admission")}>
            Admission Process
          </Button>
          <Button variant="text" onClick={() => onItemSelected("about")}>
            About
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default withWidth()(withStyles(styles, { withTheme: true })(NavBar));
