import React from "react";
import s2sImage from "../../assets/s2s_image_1.jpg";
import { Grid, Box, Button, Typography, withStyles } from "@material-ui/core";
import { styles } from "./styles";

const OurApproach = React.forwardRef(({ classes, onApply }, ref) => {
  return (
    <div className={classes.root} ref={ref}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingX={3}
            paddingY={3}
          >
            <img
              style={{ maxWidth: "100%", maxHeight: 400 }}
              src={s2sImage}
              alt="meeting"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX={3}
            paddingY={3}
            height="100%"
          >
            <Typography variant="h4" className={classes.header}>
              OUR APPROACH
            </Typography>
            <Typography style={{ marginTop: 16, marginBottom: 16 }}>
              Our goal is to remove as many barriers as possible for people to
              enter the technical industry with a focus on those who have had
              limited opportunities. In many cases, the largest barrier is that
              people simply do not have the savings acquired to pursue
              programming full time. Therefore, our program members will undergo
              a rigorous 8 – 12 month paid apprenticeship. We will also provide
              them with laptops that they may keep upon graduation. During this
              time, our apprentices will gain instruction on modern programming
              technologies such as C#, JavaScript, React, SQL, and AWS.
              Participants will immediately put these skills into practice as
              they learn them via projects. Additionally, our apprentices will
              gain industry experience through their work at partner
              organizations. To ensure our apprentice’s continued success, the
              course will also cover topics such as professional development and
              general business acumen. We believe our training model reflects
              our mission while giving our apprentices a shot at success. Let us
              help you achieve your goals. Apply today!
            </Typography>
            <Button
              color="primary"
              variant="contained"
              className={classes.roundButton}
              onClick={onApply}
            >
              Apply Now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(OurApproach);
