import { useRef } from "react";
import NavBar from "../components/Navigation/NavBar";
import HeaderSection from "../components/HeaderSection/HeaderSection";
import Partners from "../components/Partners/Partners";
import OurApproach from "../components/OurApproach/OurApproach";
import Testimonials from "../components/Testimonials/Testimonials";
import Problem from "../components/Problem/Problem";
import StatsArea from "../components/StatsArea/StatsArea";
import AdmissionProcess from "../components/AdmissionProcess/AdmissionProcess";
import AboutUs from "../components/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";
import { useHistory } from "react-router-dom";

const Home = () => {
  const statsRef = useRef();
  const partnersRef = useRef();
  const approachRef = useRef();
  const testimonialsRef = useRef();
  const admissionRef = useRef();
  const aboutRef = useRef();
  const problemRef = useRef();

  const history = useHistory();

  const onScrollToArea = (section) => {
    switch (section) {
      case "partners":
        partnersRef.current.scrollIntoView();
        break;
      case "approach":
        approachRef.current.scrollIntoView();
        break;
      case "testimonials":
        testimonialsRef.current.scrollIntoView();
        break;
      case "stats":
        statsRef.current.scrollIntoView();
        break;
      case "admission":
        admissionRef.current.scrollIntoView();
        break;
      case "about":
        aboutRef.current.scrollIntoView();
        break;
      case "problem":
        problemRef.current.scrollIntoView();
      default:
        return;
    }
  };

  const openApplicationHandler = () => {
    history.push("./apply");
  };

  return (
    <>
      <NavBar onItemSelected={onScrollToArea} />
      <HeaderSection onApply={openApplicationHandler} />
      {/* <Partners ref={partnersRef} /> */}
      <OurApproach ref={approachRef} onApply={openApplicationHandler} />
      <Problem ref={problemRef} onApply={openApplicationHandler} />
      {/* <Testimonials ref={testimonialsRef} /> */}
      <StatsArea ref={statsRef} />
      <AdmissionProcess ref={admissionRef} onApply={openApplicationHandler} />
      <AboutUs ref={aboutRef} onApply={openApplicationHandler} />
      <Footer />
    </>
  );
};

export default Home;
