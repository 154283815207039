import emailjs from "emailjs-com";

export const sendNotification = async (service, data) => {
  let SERVICE_ID = "service_wjsnx5n";
  let TEMPLATE_ID = "";
  if (service === "newsletter") {
    TEMPLATE_ID = "template_jat0cfp";
  } else if (service === "application") {
    TEMPLATE_ID = "template_i1ic1e5";
  }
  if (TEMPLATE_ID !== "") {
    await emailjs.send(
      SERVICE_ID,
      TEMPLATE_ID,
      {
        to_name: data.name,
        message: data.message,
        to_email: data.to
      },
      process.env.REACT_APP_EMAILJS_USER
    );
  }
};
