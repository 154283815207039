import React from "react";
import { Typography, Box, Button, Hidden, withStyles } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { styles } from "./styles";
import s2sApply from "../../assets/s2s_apply.png";
import s2sTest from "../../assets/s2s_test.png";
import s2sInterview from "../../assets/s2s_interview.png";
import s2sEnroll from "../../assets/s2s_enroll.png";

const AdmissionProcess = React.forwardRef(({ classes, onApply }, ref) => {
  return (
    <div className={classes.root} ref={ref}>
      <Typography variant="h4" style={{ alignSelf: "center" }}>
        ADMISSION PROCESS
      </Typography>
      <Hidden smDown>
        <Timeline align="left" style={{ paddingLeft: 64 }}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <div className={classes.timelineDot}>
                  <Typography variant="h4">1</Typography>
                </div>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent
              className={classes.timelineOppositeContent}
            />
            <TimelineContent>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                height="100%"
              >
                <img
                  src={s2sApply}
                  alt="apply on the computer"
                  style={{
                    marginLeft: 24,
                    marginRight: 48
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h5">APPLY</Typography>
                  <Typography>
                    Fill out a brief application to tell us more about yourself
                    and why you want to learn to code.
                  </Typography>
                  {/* <Button variant="text" color="primary">
                  Get Started
                </Button> */}
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <div className={classes.timelineDot}>
                  <Typography variant="h4">2</Typography>
                </div>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent
              className={classes.timelineOppositeContent}
            />
            <TimelineContent>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                height="100%"
              >
                <img
                  src={s2sTest}
                  alt="apptitude test"
                  style={{
                    marginLeft: 24,
                    marginRight: 48
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h5">APTITUDE TEST</Typography>
                  <Typography>
                    A quick assesment to see where your strengths lie.
                  </Typography>
                  {/* <Button variant="text" color="primary">
                  Learn More
                </Button> */}
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <div className={classes.timelineDot}>
                  <Typography variant="h4">3</Typography>
                </div>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent
              className={classes.timelineOppositeContent}
            />
            <TimelineContent>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                height="100%"
              >
                <img
                  src={s2sInterview}
                  alt="interview"
                  style={{
                    marginLeft: 24,
                    marginRight: 48
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="h5">INTERVIEW</Typography>
                  <Typography>
                    We will contact you to schedule a time to chat so we can
                    learn more about you and answer questions about what
                    spark2start can offer.
                  </Typography>
                  {/* <Button variant="text" color="primary">
                  Learn More
                </Button> */}
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <div className={classes.timelineDot}>
                  <Typography variant="h4">4</Typography>
                </div>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineOppositeContent
              className={classes.timelineOppositeContent}
            />
            <TimelineContent>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                height="100%"
              >
                <img
                  src={s2sEnroll}
                  alt="enroll computer"
                  style={{
                    marginLeft: 24,
                    marginRight: 48
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography color="primary" variant="h5">
                    ENROLL!
                  </Typography>
                  <Typography></Typography>
                  {/* <Button variant="text" color="primary">
                  Apply
                </Button> */}
                </Box>
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Hidden>
      <Hidden mdUp>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingX={2}
        >
          <div className={classes.mobileTimelineSection}>
            <div className={classes.timelineDot}>
              <Typography variant="h4">1</Typography>
            </div>
            <img
              src={s2sApply}
              style={{ margin: 16 }}
              alt="apply on the computer"
            />
            <Typography
              style={{ marginTop: 16, marginBottom: 16 }}
              variant="h5"
            >
              APPLY
            </Typography>
            <Typography align="center">
              Fill out a brief application to tell us more about yourself and
              why you want to learn to code.
            </Typography>
            {/* <Button variant="text" color="primary">
              Get Started
            </Button> */}
          </div>
          <div className={classes.mobileTimelineSection}>
            <div className={classes.timelineDot}>
              <Typography variant="h4">2</Typography>
            </div>
            <img src={s2sTest} style={{ margin: 16 }} alt="apptitude test" />
            <Typography
              style={{ marginTop: 16, marginBottom: 16 }}
              variant="h5"
            >
              APPTITUDE TEST
            </Typography>
            <Typography align="center">
              A quick assesment to see where your strengths lie.
            </Typography>
            {/* <Button variant="text" color="primary">
              Learn More
            </Button> */}
          </div>
          <div className={classes.mobileTimelineSection}>
            <div className={classes.timelineDot}>
              <Typography variant="h4">3</Typography>
            </div>
            <img src={s2sInterview} style={{ margin: 16 }} alt="interview" />
            <Typography
              style={{ marginTop: 16, marginBottom: 16 }}
              variant="h5"
            >
              INTERVIEW
            </Typography>
            <Typography align="center">
              We will contact you to schedule a time to chat so we can learn
              more about you and answer questions about what spark2start can
              offer.
            </Typography>
            {/* <Button variant="text" color="primary">
              Learn More
            </Button> */}
          </div>
          <div className={classes.mobileTimelineSection}>
            <div className={classes.timelineDot}>
              <Typography variant="h4">4</Typography>
            </div>
            <img src={s2sEnroll} style={{ margin: 16 }} alt="enroll computer" />
            <Typography
              style={{ marginTop: 16, marginBottom: 16 }}
              color="primary"
              variant="h5"
            >
              ENROLL!
            </Typography>
            <Typography></Typography>
            {/* <Button variant="text" color="primary">
              Apply
            </Button> */}
          </div>
        </Box>
      </Hidden>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={classes.separator} />
        <Button
          color="primary"
          variant="contained"
          className={classes.roundButton}
          onClick={onApply}
        >
          Get Started
        </Button>
        <div className={classes.separator} />
      </Box>
    </div>
  );
});
export default withStyles(styles, { withTheme: true })(AdmissionProcess);
