export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.common.GRAY,
    paddingTop: 64,
    paddingBottom: 64
  },
  roundButton: {
    width: 175,
    borderRadius: 20
  },
  header: {
    fontFamily: "Brandon-Bold"
  }
});
