import React from "react";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import linearGradient from "../../assets/s2s_linear_gradient.jpg";
import { styles } from "./styles";
import logo_no_text from "../../assets/s2s_logo_no_text.png";

const StatsArea = React.forwardRef(({ classes }, ref) => {
  return (
    <div
      ref={ref}
      className={classes.root}
      style={{
        backgroundImage: `url(${linearGradient})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <Grid container className={classes.gridContainer}>
        <Grid item xs={6} md={3} className={classes.gridItem}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3" style={{ color: "white" }}>
              $42K +
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "white", paddingLeft: 16, paddingRight: 16 }}
            >
              Average cost of 4-year degress
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3} className={classes.gridItem}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3" style={{ color: "white" }}>
              $13.5K +
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "white", paddingLeft: 16, paddingRight: 16 }}
            >
              Average cost of bootcamp
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3} className={classes.gridItem}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3" style={{ color: "white" }}>
              $0
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "white", paddingLeft: 16, paddingRight: 16 }}
            >
              spark2start approach
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={3} className={classes.gridItem}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <img src={logo_no_text} alt="spark2start logo" />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(StatsArea);
