import React from "react";
import s2sImage from "../../assets/s2s_image_2.png";
import { Grid, Box, Button, Typography, withStyles } from "@material-ui/core";
import { styles } from "./styles";

const AboutUs = React.forwardRef(({ classes, onApply }, ref) => {
  return (
    <div className={classes.root} ref={ref}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingX={3}
            paddingY={3}
          >
            <img
              style={{ maxWidth: "100%", maxHeight: 400 }}
              src={s2sImage}
              alt="meeting"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            paddingX={3}
            paddingY={3}
            height="100%"
          >
            <Typography variant="h4" className={classes.header}>
              ABOUT US
            </Typography>
            <Typography style={{ marginTop: 16, marginBottom: 16 }}>
              As a local and national leader in tech, Unicentric has the
              connections and experience to aid spark2start in its mission of
              helping black and minority Americans with a high-school degree or
              GED equivalent gain access to high-paying jobs in a technical
              field. Unicentric was founded with the mission of creating
              software for people who help people. Since our inception, we have
              strived to create high quality software for a wide array of
              non-profit organizations across the U.S. In 2015, Unicentric
              relocated its offices to the distrissed community of Braddock, PA
              in an effort to bring business and tech to the area. However,
              following some of the events of 2020, we decided it was time to do
              more and created spark2start. spark2start overcomes the financial
              roadblocks for participants by providing them a paycheck which is
              funded by partnering with businesses, such as Unicentric, that
              underwrite the program and provide gainful employment for
              participants. Take the first step to your new career by applying
              today!
            </Typography>
            <Button
              color="primary"
              variant="contained"
              className={classes.roundButton}
              onClick={onApply}
            >
              Apply Now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(AboutUs);
