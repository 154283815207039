import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  TextField,
  Paper,
  Grid,
  InputLabel,
  MenuItem,
  Hidden,
  Box,
  Tooltip,
  CircularProgress,
  withStyles
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import PhoneMask from "react-phone-input-2";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { testEmail } from "../functions/testEmail";
import { sendNotification } from "../functions/sendNotification";
import {
  getSession,
  setSession,
  clearSession
} from "../functions/localStorage";
import { useGoogleSheets } from "../context/GoogleSheets";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { STATES } from "../data/States";
import s2sbackground from "../assets/s2s_linear_gradient.png";
import s2slogo from "../assets/s2s_logo_alt.png";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const styles = (theme) => ({
  loadingIndicator: {
    color: "green",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  submitWrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
});

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  dob: new Date(),
  email: "",
  phone: "",
  preferredMethod: null,
  street: "",
  city: "",
  state: "PA",
  zip: "",
  education: null,
  whyCode: ""
};

const ApplicationScreen = ({ classes }) => {
  const [formVals, setFormVals] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const google = useGoogleSheets();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const sessionSavedForm = getSession("application");
    if (sessionSavedForm && sessionSavedForm !== "") {
      setFormVals(JSON.parse(sessionSavedForm));
    }
  }, []);
  const onSubmitForm = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const isValid = validateForm();
    if (isValid) {
      const {
        firstName,
        lastName,
        email,
        phone,
        state,
        street,
        city,
        zip,
        dob,
        preferredMethod = "",
        education,
        whyCode
      } = formVals;
      const sheet = await google.doc.sheetsByTitle["Applications"];
      await sheet.addRow({
        First_Name: firstName,
        Last_Name: lastName,
        DOB: new Date(dob).toDateString(),
        Email: email,
        Phone: phone,
        Preferred_Contact_Method: preferredMethod,
        Street_Address: street,
        City: city,
        State: state,
        Zip_Code: zip,
        Highest_Education_Attained: education,
        Why_Code: whyCode,
        Created: new Date().toUTCString()
      });
      await sendNotification("application", {
        to_name: firstName,
        to: email,
        message: ""
      });
      clearSession();
      setIsSubmitting(false);
      alert(
        "Thank you for your application to spark2start. An email has been sent to the address you provided with next steps. \n*Make sure to check your spam folder for the email* \nWe look forward to speaking with you soon!"
      );
      history.replace("/");
    }
  };

  const saveFormData = _.debounce(() => {
    setSession("application", JSON.stringify(formVals));
  }, 300);

  const onFieldChange = (val, key) => {
    setFormVals((prev) => ({
      ...prev,
      [key]: val
    }));
  };
  const validateForm = () => {
    let newErrors = {};
    const {
      firstName,
      lastName,
      email,
      phone,
      state,
      street,
      city,
      zip,
      preferredMethod,
      education,
      whyCode
    } = formVals;
    if (firstName.trim() === "") {
      newErrors["firstName"] = "* Please include your first name.";
    }
    if (lastName.trim() === "") {
      newErrors["lastName"] = "* Please include your last name.";
    }
    if (!testEmail(email)) {
      newErrors["email"] =
        "* Please include a valid email address at which we can reach you.";
    }
    if (!new RegExp(/^\d{10}$/).test(phone)) {
      newErrors["phone"] =
        "* Please include a valid phone number at which we can reach you.";
    }
    if (state.trim() === "") {
      newErrors["state"] = " * Please include your state.";
    }
    if (street.trim() === "") {
      newErrors["street"] = "* Please include your street address.";
    }
    if (city.trim() === "") {
      newErrors["city"] = "* Please include your city.";
    }
    if (zip.trim() === "") {
      newErrors["zip"] = "* Please include your zip code.";
    }
    // if (!preferredMethod) {
    //   newErrors["preferredMethod"] =
    //     "* Please indicate your preferred contact method.";
    // }
    if (!education) {
      newErrors["education"] = "* Please indicate your highest academic level.";
    }
    if (whyCode.trim() === "") {
      newErrors["whyCode"] =
        "* Please tell us why you are interested in spark2start.";
    }
    if (_.isEmpty(newErrors)) {
      //submit
      setErrors({});
      return true;
    } else {
      setErrors(newErrors);
      return false;
    }
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ padding: 16, margin: "auto" }}>
        <form onSubmit={onSubmitForm} noValidate onBlur={saveFormData}>
          <Paper style={{ padding: 16 }}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    backgroundColor: "#aaa",
                    borderRadius: 8,
                    padding: 16,
                    border: "3px solid #1F896F"
                  }}
                >
                  {/* <Typography variant="h3">spark2start application</Typography> */}
                  <img src={s2slogo} />
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: 24 }}
                    color="primary"
                  >
                    Please fill out the required fields below
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  autoCapitalize="words"
                  variant="outlined"
                  value={formVals.firstName}
                  onChange={(e) => onFieldChange(e.target.value, "firstName")}
                  label="First Name"
                  error={Boolean(errors["firstName"])}
                  helperText={errors["firstName"] || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  autoCapitalize="words"
                  variant="outlined"
                  value={formVals.lastName}
                  onChange={(e) => onFieldChange(e.target.value, "lastName")}
                  label="Last Name"
                  error={Boolean(errors["lastName"])}
                  helperText={errors["lastName"] || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <KeyboardDatePicker
                  required
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="dob"
                  fullWidth
                  inputVariant="outlined"
                  label="Date of Birth"
                  value={formVals.dob}
                  onChange={(val) => onFieldChange(val, "dob")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>
              <Hidden mdDown>
                <Grid item xs={6}></Grid>
              </Hidden>

              <Grid item md={6} xs={12}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  value={formVals.email}
                  onChange={(e) => onFieldChange(e.target.value, "email")}
                  label="Email Address"
                  error={Boolean(errors["email"])}
                  helperText={errors["email"] || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl>
                  <PhoneMask
                    id="phone-input"
                    disableCountryCode={true}
                    inputProps={{
                      name: "phone",
                      required: true
                    }}
                    containerStyle={{ width: "100%", borderColor: "green" }}
                    inputStyle={{
                      borderColor: Boolean(errors["phone"]) ? "red" : "black"
                    }}
                    placeholder={"(123) 456-7890"}
                    onlyCountries={["us"]}
                    masks={{ us: "(...) ...-...." }}
                    value={formVals.phone}
                    onChange={(val) => onFieldChange(val, "phone")}
                    country="us"
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {errors["phone"] || ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Preferred Contact Method
                  </FormLabel>
                  <RadioGroup
                    row
                    value={formVals.preferredMethod}
                    onChange={(e) =>
                      onFieldChange(e.target.value, "preferredMethod")
                    }
                  >
                    <FormControlLabel
                      value="call"
                      control={<Radio />}
                      label="Phone Call"
                    />
                    <FormControlLabel
                      value="text"
                      control={<Radio />}
                      label="Text Message"
                    />
                    <FormControlLabel
                      value="email"
                      control={<Radio />}
                      label="Email"
                    />
                    <FormControlLabel
                      value="any"
                      control={<Radio />}
                      label="Any"
                    />
                  </RadioGroup>
                  <FormHelperText style={{ color: "red" }}>
                    {errors["preferredMethod"] || ""}
                  </FormHelperText>
                </FormControl>
              </Grid> */}
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  value={formVals.street}
                  onChange={(e) => onFieldChange(e.target.value, "street")}
                  label="Street Address"
                  error={Boolean(errors["street"])}
                  helperText={errors["street"] || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  value={formVals.city}
                  onChange={(e) => onFieldChange(e.target.value, "city")}
                  label="City"
                  error={Boolean(errors["city"])}
                  helperText={errors["city"] || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  select
                  value={formVals.state}
                  onChange={(e) => onFieldChange(e.target.value, "state")}
                  label="State"
                  error={Boolean(errors["state"])}
                  helperText={errors["state"] || ""}
                >
                  {STATES.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  value={formVals.zip}
                  onChange={(e) => onFieldChange(e.target.value, "zip")}
                  label="Zip Code"
                  error={Boolean(errors["zip"])}
                  helperText={errors["zip"] || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Highest Academic Level
                  </FormLabel>
                  <RadioGroup
                    row
                    value={formVals.education}
                    onChange={(e) => onFieldChange(e.target.value, "education")}
                  >
                    <Tooltip title="You’ve earned a high school diploma or General Equivalency Diploma (GED).">
                      <FormControlLabel
                        value="High school or equivalent"
                        control={<Radio />}
                        label="High school or equivalent"
                      />
                    </Tooltip>
                    <Tooltip title="You’ve received training in a special skill to be used in a trade (i.e., mechanical, electrical, carpentry, etc.), or completed an organized program of study given by a recognized body or authority in a given subject area (i.e., MCSE, Java, CFA, Series 7, etc.)">
                      <FormControlLabel
                        value="Technical or occupational certificate"
                        control={<Radio />}
                        label="Technical or occupational certificate"
                      />
                    </Tooltip>
                    <Tooltip title="You’ve completed undergraduate studies and earned a 2-year degree at an accredited institution.">
                      <FormControlLabel
                        value="Associate degree"
                        control={<Radio />}
                        label="Associate degree"
                      />
                    </Tooltip>
                    <Tooltip title="You’ve attended college but haven’t earned a degree.">
                      <FormControlLabel
                        value="Some college coursework completed"
                        control={<Radio />}
                        label="Some college coursework completed"
                      />
                    </Tooltip>
                    <Tooltip title="You’ve earned a 4-year degree (i.e., BA, BS, etc.) from an accredited academic institution.">
                      <FormControlLabel
                        value="Bachelor’s degree"
                        control={<Radio />}
                        label="Bachelor’s degree"
                      />
                    </Tooltip>
                    <Tooltip title="You’ve earned a master’s level degree (i.e., MBA, etc.), doctorate degree (i.e., PhD), title or rank of doctor or professional degree (i.e., law, medical, dental, etc.) from an accredited academic institution.">
                      <FormControlLabel
                        value="Master’s degree or higher"
                        control={<Radio />}
                        label="Master’s degree or higher"
                      />
                    </Tooltip>
                  </RadioGroup>
                  <FormHelperText style={{ color: "red" }}>
                    {errors["education"] || ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor="why-code-input">
                  Tell us a little bit about why you want to learn to code.
                </InputLabel>
                <TextField
                  id="why-code-input"
                  value={formVals.whyCode}
                  onChange={(e) => onFieldChange(e.target.value, "whyCode")}
                  fullWidth
                  multiline
                  required
                  variant="outlined"
                  error={Boolean(errors["whyCode"])}
                  helperText={errors["whyCode"] || ""}
                  rows={10}
                />
              </Grid>

              <div className={classes.submitWrapper}>
                <Button
                  disabled={isSubmitting}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Submit Application
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.loadingIndicator}
                  />
                )}
              </div>

              <Grid item xs={12}>
                <Box height={300} />
              </Grid>
            </Grid>
          </Paper>
        </form>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles, { withTheme: true })(ApplicationScreen);
