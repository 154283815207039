import {
  Typography,
  Button,
  Box,
  Grid,
  Hidden,
  withStyles
} from "@material-ui/core";
import headerImage from "../../assets/s2s_header.jpg"; //1552 x 600
import { styles } from "./styles";
import useWindowSize from "../../hooks/useWindowSize";

const HeaderSection = ({ classes, onApply }) => {
  const windowSize = useWindowSize();

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          height: windowSize.width / 2.58666
        }}
        className={classes.container}
      >
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={6} style={{ height: "100%" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              paddingX={10}
            >
              <div>
                <Typography variant="h4" className={classes.textPrimary}>
                  LEARN TO CODE
                </Typography>
                <Typography
                  style={{ marginBottom: 16 }}
                  variant="h4"
                  className={classes.textPrimary}
                >
                  CONTROL YOUR FUTURE
                </Typography>
                <Hidden smDown>
                  <>
                    <Typography
                      className={classes.textSecondary}
                      variant="body1"
                    >
                      Develop the skills needed to succeed in the tech industry
                      while working full-time.
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      paddingY={3}
                    >
                      <Button
                        className={classes.roundButton}
                        color="primary"
                        variant="contained"
                        onClick={onApply}
                      >
                        Apply
                      </Button>
                      {/* <Typography
                        className={classes.textSecondary}
                        variant="subtitle1"
                        style={{
                          marginLeft: 32,
                          marginRight: 32,
                          fontSize: 12
                        }}
                      >
                        or
                      </Typography>
                      <Button style={{ color: "white" }} variant="text">
                        Call To Action
                      </Button> */}
                    </Box>
                  </>
                </Hidden>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Hidden smUp>
        <div className={classes.mobileInfoSection}>
          <Typography
            style={{ margin: 12 }}
            className={classes.textSecondary}
            variant="body1"
            align="center"
          >
            Develop the skills needed to succeed in the tech industry while
            working full-time.
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            paddingX={2}
            paddingY={2}
          >
            <Button
              className={classes.roundButton}
              color="primary"
              variant="contained"
              onClick={onApply}
            >
              Apply
            </Button>
            {/* <Typography className={classes.textSecondary} variant="subtitle1">
              or
            </Typography>
            <Button variant="text">Call To Action</Button> */}
          </Box>
        </div>
      </Hidden>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(HeaderSection);
