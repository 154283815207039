import { useState } from "react";
import { Button, Typography, Hidden, withStyles } from "@material-ui/core";
import { styles } from "./styles";
import { useGoogleSheets } from "../../context/GoogleSheets";
import { testEmail } from "../../functions/testEmail";
import { sendNotification } from "../../functions/sendNotification";

const Footer = ({ classes }) => {
  const [email, setEmail] = useState("");
  const google = useGoogleSheets();

  const subscribeToEmailList = async () => {
    const isValidEmailAddress = testEmail(email);
    const _email = email;
    if (!isValidEmailAddress) {
      alert("Please enter a valid email address to subscribe.");
      return;
    }
    const sheet = await google.doc.sheetsByTitle["Email_List"];
    await sheet.addRow({ Email: email, Created: new Date().toUTCString() });

    alert(
      "Thanks for your interest in spark2start! You have successfully been added to our email list."
    );
    setEmail("");
    await sendNotification("newsletter", {
      name: _email,
      message: "",
      to: _email
    });
  };

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div className={classes.newsletterSection}>
          <Typography noWrap style={{ color: "white" }}>
            Subscribe to our newsletter
          </Typography>

          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={classes.emailInput}
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.roundButton}
            onClick={subscribeToEmailList}
          >
            Subscribe
          </Button>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className={classes.mobileNewsletterSection}>
          <Typography noWrap style={{ color: "white" }}>
            Subscribe to our newsletter
          </Typography>

          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={classes.emailInput}
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.roundButton}
            onClick={subscribeToEmailList}
          >
            Subscribe
          </Button>
        </div>
      </Hidden>
      <div style={{ height: 400 }} />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Footer);
