export const styles = (theme) => ({
  root: {
    width: "100%",
    height: 290,
    [theme.breakpoints.down("sm")]: {
      height: 580
    }
  },
  gridContainer: {
    height: "100%"
  },
  gridItem: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "50%"
    }
  }
});
