import { GoogleSpreadsheet } from "google-spreadsheet";
import { createContext, useContext, useEffect, useState } from "react";

class GoogleSheet {
  constructor() {
    this.doc = new GoogleSpreadsheet(
      "1psl2d5gv-WVThTftJZJTIzVEZj1ok6gKxlCRM0wB5oM"
    );
  }
  initialize = async () => {
    await this.doc.useServiceAccountAuth({
      client_email: process.env.REACT_APP_GOOGLE_ACCOUNT_EMAIL,
      private_key: process.env.REACT_APP_GOOGLE_KEY.replace(/\\n/g, "\n")
    });
    await this.doc.loadInfo();
  };
}

export const GoogleSheetsContext = createContext();

export const useGoogleSheets = () => useContext(GoogleSheetsContext);

const GoogleSheetsProvider = ({ children }) => {
  const [google, setGoogle] = useState(null);
  useEffect(() => {
    (async () => {
      const sheet = new GoogleSheet();
      await sheet.initialize();
      setGoogle(sheet);
    })();
  }, []);
  return (
    <GoogleSheetsContext.Provider value={google}>
      {children}
    </GoogleSheetsContext.Provider>
  );
};

export default GoogleSheetsProvider;
