import React from "react";
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  withStyles
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { styles } from "./styles";
import useWindowSize from "../../hooks/useWindowSize";

const DATA = [
  {
    name: "Jane Doe",
    text:
      "lhsdfjkahlfjkdl fhjakshf jasfkl dksafhdjksal hasjdkfh ahdafj jsdkfhajk sskhfd",
    rating: 5
  },
  {
    name: "Jane Doe",
    text:
      "lhsdfjkahlfjkdl fhjakshf jasfkl dksafhdjksal hasjdkfh ahdafj jsdkfhajk sskhfd",
    rating: 4.5
  },
  {
    name: "Jane Doe",
    text:
      "lhsdfjkahlfjkdl fhjakshf jasfkl dksafhdjksal hasjdkfh ahdafj jsdkfhajk sskhfd",
    rating: 5
  },
  {
    name: "Jane Doe",
    text:
      "lhsdfjkahlfjkdl fhjakshf jasfkl dksafhdjksal hasjdkfh ahdafj jsdkfhajk sskhfd",
    rating: 4
  },
  {
    name: "Jane Doe",
    text:
      "lhsdfjkahlfjkdl fhjakshf jasfkl dksafhdjksal hasjdkfh ahdafj jsdkfhajk sskhfd",
    rating: 4.5
  },
  {
    name: "Jane Doe",
    text:
      "lhsdfjkahlfjkdl fhjakshf jasfkl dksafhdjksal hasjdkfh ahdafj jsdkfhajk sskhfd",
    rating: 5
  }
];

const array_chunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

const Testimonials = React.forwardRef(({ classes }, ref) => {
  return (
    <div className={classes.root} ref={ref}>
      <Typography style={{ padding: 48 }}>TESTIMONIALS</Typography>

      <div className={classes.cardSection}>
        {DATA.map((item, i) => (
          <Card key={`${i}`} variant="outlined" className={classes.cardRoot}>
            <CardContent>
              <Typography
                style={{ textTransform: "capitalize" }}
                color="primary"
              >
                {item.name}
              </Typography>
              <Typography>{item.text}</Typography>
            </CardContent>
            <CardActions>
              <Rating readOnly value={item.rating} precision={0.5} />
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(Testimonials);
