export const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.GRAY,
    height: 300,
    width: "100%"
  },
  cardSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "auto",
    marginLeft: 32,
    marginRight: 32
  },
  cardRoot: {
    minWidth: 302.68,
    height: 204,
    marginLeft: 16,
    marginRight: 16
  }
});
